import React from 'react'
import Img from "../../image/train.jpg"
import Img2 from "../../image/bus.jpg"
import Img3 from "../../image/hotel.jpg"
import img4 from "../../image/holiday.jpg"
import Img5 from "../../image/flight.jpg"
import Img6 from "../../image/Amazing-Deals.jpg"
import Img7 from "../../image/Unique-Features.jpg"
import Img8 from "../../image/Accounting-Reports.jpg"
import Img9 from "../../image/Multiple-Services.jpg"
import Img10 from "../../image/Customization.jpg"
import Img11 from "../../image/Customer-Care.jpg"



import { MdKeyboardDoubleArrowRight } from "react-icons/md";





const Travel = () => {
  return (
    <div>
      <div className='container mt-5 mb-2'>
        <h2 className='font-bolds text-center mt-5 mb-2' data-aos="fade-up">Services!!!</h2>
        <div className='row g-3 d-flex justify-content-center gap-5 mt-4 mb-2'>
          <div className='col-sm-3 d-flex flex-column align-items-center custom-shadow mt-4 mb-2' data-aos="fade-up" >
            <img src={Img5} alt='icons' width={120} className='text-center   img-zooms'  />
            <h5 className='text-center'>Flight</h5>
        
          </div>
          <div className='col-sm-3 d-flex flex-column align-items-center custom-shadow mt-4 mb-2' data-aos="fade-up">
            <img src={Img3} alt='icons' width={120} className='text-center img-zooms' />
            <h5 className='text-center'>Hotel</h5>
        
          </div>
          <div className='col-sm-3 d-flex flex-column align-items-center custom-shadow mt-4 mb-2' data-aos="fade-up">
            <img src={Img2} alt='icons' width={120} className='text-center img-zooms' />
            <h5 className='text-center'>Bus</h5>
         
          </div>
          <div className='col-sm-3 d-flex flex-column align-items-center custom-shadow mt-4 mb-2' data-aos="fade-up">
            <img src={img4} alt='icons' width={120} className='text-center img-zooms' />
            <h5 className='text-center'>Holidays</h5>
         
          </div>
        </div>
      </div>
      <div className='container'>
        <h2 className='font-bolds text-center mt-5 mb-2' data-aos="fade-up">Unique Features
        !!!</h2>
        <div className='row mt-4 mb-2'>
        <div className='col-lg-4'>
          <div className='d-flex gap-2' >
            <img src={Img6} alt='icons' width={120} className='text-center   img-zooms '/>
            <p><h5>Amazing Deals</h5>Best Deals on all the Domestic and International flights with instant bookings and real-time commission on every transaction.</p>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='d-flex gap-2' >
            <img src={Img7} alt='icons' width={120} className='text-center   img-zooms '/>
            <p><h5>Unique Features</h5>Advanced features, such as travel calendar based on booking and travelling date, single search for round trip and round trip special fare.</p>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='d-flex gap-2' >
            <img src={Img8} alt='icons' width={120} className='text-center   img-zooms '/>
            <p><h5>Accounting Reports</h5>Multiple reports to manage all your daily financial transactions in the system be it accounting or tax liabilities, all available in one click.</p>
          </div>
        </div>
        </div>
        <div className='row mt-4 mb-2'>
    
      
        <div className='col-lg-4'>
          <div className='d-flex gap-2' >
            <img src={Img9} alt='icons' width={120} className='text-center   img-zooms '/>
            <p><h5>Multiple Services</h5>We offer an all-inclusive travel experience – flight, hotel, bus, railway & holidays. Look no further and enjoy a gamut of services at one place.</p>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='d-flex gap-2' >
            <img src={Img10} alt='icons' width={120} className='text-center   img-zooms '/>
            <p><h5>Customization</h5>You can upload your own logo, add pre-mark-ups or add mark up once the booking is done, customise message in tickets.</p>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='d-flex gap-2' >
            <img src={Img11} alt='icons' width={120} className='text-center   img-zooms '/>
            <p><h5>Customer Care</h5>A dedicated customer care team to assist 24/7, provide all the trainings and help in smooth and hassle-free transactions.</p>
          </div>
        </div>
        </div>
      </div>
      <section className='bg10 mt-5 mb-2'>
 <div className="container ">
      <div className="card p-5 shadow-sms mt-4 mb-2 " data-aos="fade-right">
        {/* <h2 className="text-center mb-4 font-bolds" >Earn Up-To 50,000 Every Month</h2> */}
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12 mt-2 mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Name" />
            </div>
            <div className="form-group col-md-12 mt-2  mb-2" >
              <input type="email" className="form-controls px-2 py-2" placeholder="Email Address" />
            </div>
            <div className="form-group col-md-12  mt-2  mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Mobile Number" />
            </div>
          </div>
          <div className="form-row  ">
            <div className="form-group col-lg-12 mt-2  mb-2">
              <select className="form-controls px-2 py-2">
                <option value="Retailer">Retailer</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>
            <div className="form-group col-md-12 mt-2  mb-2">
              <button type="submit" className="btn btn-primarys ">Join Now <MdKeyboardDoubleArrowRight/></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </section>
    </div>
  )
}

export default Travel