import React from "react";
import Img1 from "../../image/spoken-english.png";
import Img2 from "../../image/spoken-about.png";
import Img3 from "../../image/pte-side1.png";
import Img4 from "../../image/pte-institute.png";
import Img5 from "../../image/pte-about.png";




import { MdKeyboardDoubleArrowRight } from "react-icons/md";


const UtilityServices = () => {
  return (
    <>
    <div className="container" style={{background:"#eff9ff" ,padding:"10px"}}>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex gap-2">
            <div className="icon">
            <h2 className="review">Spoken English</h2>
              <p>
              Nowadays, everyone wants to improve their spoken English skills because it has become a necessary skill. Aspiring students often Search for an institute that can provide excellent training in spoken English has become a tough job. Students who desire to study abroad cannot ignore the fact that Spoken English skills are the backbone of studying abroad.

As India is the fastest growing economy in the world, leading companies of developed and developing nations are keen in having a close network with emerging Indian companies. To meet the world standards, many of the Indian companies prefer to hire candidates with impeccable language ability.

To be successful you must possess good communication skills in English. If you lack spoken English skills then your chances of studying in a foreign land become dim and you in spite of having good experience would lose the opportunity.

We, at Pyramid, provide you with the best teaching methodology under the guidance of experienced and dedicated staff. Our courses are specifically designed for school students, college students, job aspirants, professionals, those appearing in international exams, housewives and all those for whom speaking good English is important.
              </p>
         
          
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="img-container">
            <img
              src={Img1}
              alt="img"
              data-aos="fade-right"
              className="img-zoomes"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="container mt-4">
      <div className="row">
      <div className="col-lg-6">
          <div className="img-container">
            <img
              src={Img2}
              alt="img"
              data-aos="fade-right"
              className="img-zoomes"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex gap-2">
            <div className="icon">
            <h2 className="review">About the Course</h2>
              <p>
              We have an integrated course for Spoken English, which is a complete kit in itself for Spoken English and overall Personality Development. The program comprises different levels and stages for students based on their skills. As you scale higher levels, you face more challenging stuff, but the content remains the same.

The course provides a balanced curriculum, stressing the speaking, listening, reading, and writing skills, also covering essential grammar, vocabulary, pronunciation, idioms, and colloquial language.

This course is very important for the students, professionals, and even housewives who require English to work, study, or travel. In addition, it can prepare you for several exams, including IELTS, TOEFL, PTE.
              </p>
         
          
            </div>
          </div>
        </div>
     
      </div>
    </div>
    <div className="container mt-4" style={{background:"#eff9ff" ,padding:"10px"}}>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex gap-2">
            <div className="icon">
            <h2 className="review">Pyramid eServices PTE Institute</h2>
              <p>
              PTE and Spoken English Training
Pyramid eServices is one of the best PTE institutions in Jalandhar. Apart from PTE, we also prepare students for the IELTS Exam and Spoken English. Our experienced and qualified faculty has helped shape the careers of thousands of students. We believe that each student's English needs are different, so we give individual attention to each student to properly address the weaknesses of the English language. Moreover, we believe that every student has the caliber of achieving their dreams; they just need the right guidance to succeed. So, we, at Pyramid utilize our effective technique and strategies to help them grip the language to perfection.

We aim to provide quality training to our students, promote global awareness, and encourage personal development while laying the foundation for participants to become engaged global citizens.
              </p>
         
          
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="img-container">
            <img
              src={Img3}
              alt="img"
              data-aos="fade-right"
              className="img-zoomes"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="container mt-4">
      <div className="row">
      <div className="col-lg-6">
          <div className="img-container">
            <img
              src={Img4}
              alt="img"
              data-aos="fade-right"
              className="img-zoomes"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="d-flex gap-2">
            <div className="icon">
            <h2 className="review">PTE</h2>
              <p>
              Pearson Test of English
If a student aims to study in one of the world's best educational institutions, they must possess a solid academic profile. Moreover, they must be proficient in the English language because, in a foreign university, the lectures are usually delivered in English. As a matter of fact, English language proficiency is a mandatory prerequisite to get admission to a most foreign institution.

The Pearson Test of English, i.e., PTE, is a computer-based English language test aimed to check the English proficiency of those who wish to immigrate or study in an English-speaking country. The test generally takes 3 hours in which the test-taker's writing, speaking, listening, and reading skills are examined. The entire test is conducted on a computer, including the speaking module, in which the test takers' voices are recorded to gauge their speaking skills. And then, the recorded voices are forwarded for marking. The advantage of this test is that the test-takers get their result within 5 business days.

Countries like Australia, New Zealand, the United Kingdom, the USA, and Canada demand English proficiency test results from international students from non-English speaking nations. To get selected in a desired University or College, students need to acquire the required scores on the PTE test. This is done to ensure that only applicants with strong English skills get admission.
              </p>
         
          
            </div>
          </div>
        </div>
     
      </div>
    </div>
    <div className="container mt-4" style={{background:"#eff9ff" ,padding:"10px"}}>
      <div className="row">
        <div className="col-lg-6">
          <div className="d-flex gap-2">
            <div className="icon">
            <h2 className="review">About the Course</h2>
              <p>
              Pyramid eServices is well known for its excellence and teaching achievements in the PTE modules. Our PTE coaching has been widely accepted because the value of every penny you spend in the pyramid is paid. And we also ensure that our students pass the exam with good marks in all four modules, i.e. listening, writing, speaking and reading.

We at Pyramid eServices use specialized techniques to train individuals. Our institutions are well equipped with the latest technology to help our students learn English efficiently and effectively. In addition, our instructors are highly experienced who pay 100% attention to students' success.
              </p>
         
          
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="img-container">
            <img
              src={Img5}
              alt="img"
              data-aos="fade-right"
              className="img-zoomes"
            />
          </div>
        </div>
      </div>
    </div>
    <section className='bg10 mt-5 mb-2'>
 <div className="container ">
      <div className="card p-5 shadow-sms mt-4 mb-2 " data-aos="fade-right">
        {/* <h2 className="text-center mb-4 font-bolds" >Earn Up-To 50,000 Every Month</h2> */}
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12 mt-2 mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Name" />
            </div>
            <div className="form-group col-md-12 mt-2  mb-2" >
              <input type="email" className="form-controls px-2 py-2" placeholder="Email Address" />
            </div>
            <div className="form-group col-md-12  mt-2  mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Mobile Number" />
            </div>
          </div>
          <div className="form-row  ">
            <div className="form-group col-lg-12 mt-2  mb-2">
              <select className="form-controls px-2 py-2">
                <option value="Retailer">Retailer</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>
            <div className="form-group col-md-12 mt-2  mb-2">
              <button type="submit" className="btn btn-primarys ">Join Now <MdKeyboardDoubleArrowRight/></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </section>
          </>
  );
};

export default UtilityServices;
