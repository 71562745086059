import React from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { AiTwotoneMail } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";

const Contact = () => {
  return (
    <>
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55998.05981572654!2d76.852617939127!3d28.69327377158513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d094dd5088c81%3A0x9aff990a79ed3240!2sBahadurgarh%2C%20Haryana%20124507!5e0!3m2!1sen!2sin!4v1718183243647!5m2!1sen!2sin"
      width="100%"
      height="450"
      style={{ border: '0' }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
 
 

{/* <section/> */}
<section className='bg10 mt-5 mb-2'>
 <div className="container ">
      <div className="card p-5 shadow-sms mt-4 mb-2 " data-aos="fade-right">
        {/* <h2 className="text-center mb-4 font-bolds" >Earn Up-To 50,000 Every Month</h2> */}
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12 mt-2 mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Name" />
            </div>
            <div className="form-group col-md-12 mt-2  mb-2" >
              <input type="email" className="form-controls px-2 py-2" placeholder="Email Address" />
            </div>
            <div className="form-group col-md-12  mt-2  mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Mobile Number" />
            </div>
          </div>
          <div className="form-row  ">
            <div className="form-group col-lg-12 mt-2  mb-2">
              <select className="form-controls px-2 py-2">
                <option value="Retailer">Retailer</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>
            <div className="form-group col-md-12 mt-2  mb-2">
              <button type="submit" className="btn btn-primarys ">Join Now <MdKeyboardDoubleArrowRight/></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </section>
 
  </>

  )
}

export default Contact