import React ,{useEffect} from 'react';
import Img from "../image/mobile.jpg";
import Img2 from "../image/an-app.jpg";
import Img3 from "../image/computer.jpg";
import Img4 from "../image/team.jpg";
import TravelBook from "../Components/TravelBook"
import AOS from "aos";
import "aos/dist/aos.css";
const HeroSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div style={{background:"#eff9ff" ,padding:"10px"}}>

   <div className='container mt-5 mb-2' >
        {/* <h2 className=' text-center mt-5 mb-2 font-bolds' data-aos="fade-up">Features – Which Makes Us Unique!!!</h2> */}
        <div className='row g-3 d-flex justify-content-center gap-5 mt-4 mb-2'>
          <div className='col-sm-3 d-flex flex-column align-items-center custom-shadow mt-4 mb-2' data-aos="fade-up" >
            <img src={Img} alt='icons' width={120} className='text-center   img-zooms'  />
            <h5 className='text-center'>IELTS / TOEFL Training</h5>
            <p className='text-center'>
                                    Achieve desired English Language test results not only to fullfil your study abroad dream, but also to succeed in life.
                                </p>
          </div>
          <div className='col-sm-3 d-flex flex-column align-items-center custom-shadow mt-4 mb-2' data-aos="fade-up">
            <img src={Img3} alt='icons' width={120} className='text-center img-zooms' />
            <h5 className='text-center'>Career Counseling</h5>
            <p className='text-center'>
                                    
                                    Our career counseling experts offer tailored guidance to maximize your international educational experience.
                                </p>
          </div>
          <div className='col-sm-3 d-flex flex-column align-items-center custom-shadow mt-4 mb-2' data-aos="fade-up">
            <img src={Img2} alt='icons' width={120} className='text-center img-zooms' />
            <h5 className='text-center'>Study Abroad Guidance</h5>
            <p className='text-center'>
                                    Experience the freedom to choose your dream institution from hundreds of universities and colleges with expert advice to ensure your success.
                                    
                                </p>
          </div>
          <div className='col-sm-3 d-flex flex-column align-items-center custom-shadow mt-4 mb-2' data-aos="fade-up">
            <img src={Img4} alt='icons' width={120} className='text-center img-zooms' />
            <h5 className='text-center'>Pre-departure Session</h5>
            <p className='text-center'>
                                    Step into the new world with the information you need to know to shine and have a great experience.
                                </p>
          </div>
        </div>
      </div>
    <TravelBook/>
    </div>
  );
};

export default HeroSection;
