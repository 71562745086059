import React ,{useEffect} from 'react'
import Kiosk from './Kiosk'
import Img4 from "../image/schlor-bg.png"
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaRegCircleDot } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";
const Vkv = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <div style={{background:"#eff9ff" ,padding:"10px"}}>  <div className='container mt-5 mb-2'>
    <div className='row'>
    
    
     <div className='col-lg-6  '>
    
    <div className='img-container'>
            <img src={Img4} alt='img'  data-aos="fade-right" className='img-zoom'/>
      
    </div>
     </div>
     <div className='col-lg-6' data-aos="fade-left">
      <div >
      <h5 className='d-flex gap-3 font-bolds'>
        <span><FaRegCircleDot className='colors fs-2'/></span>
        New Zealand Study Visa
      </h5>
      <br/>
      <p>With excellent pedagogy and research-oriented curriculums, New Zealand's higher Education System has been ranked among the world's best. The Standard of teaching is high, and class sizes are usually small. New Zealand's degrees and diplomas are internationally recognized and highly respected, helping students enhance their career prospects manifold. The country offers different visas, namely, fee-paying student Visa, Exchange Student Visa, Foreign Government supported student visa, and pathway student visa. Depending on the study schedule under these visa types, international students may work up to 20 hours during their study program and full-time holidays.</p>
      <div  className='d-flex justify-content-between'>
    <button className='btn btn-primarys'>
    Know More <MdKeyboardDoubleArrowRight/>
    </button>
    
    
      </div>
      </div>
     </div>
    </div>
        </div></div>
        <Kiosk/>
        </>
  )
}

export default Vkv