import React ,{useEffect} from 'react'
import Vkv from './Vkv'
import Img3 from "../image/our-services.png"
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { IoBarChartOutline } from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";
const Recharge = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <div>  <div className='container mt-5 mb-2'>
    <div className='row'>
    
     <div className='col-lg-6 '>
      <div >
      <h5 className='d-flex gap-3 font-bolds' data-aos="fade-up">
      <span><IoBarChartOutline className='fs-2 colors'/></span>
      Study in Australia
      </h5>
      <br/>
      <p data-aos="fade-up"> With over 1100 educational institutes and plenty of study options in university and vocational Courses, Australia is famous as an education hub for international students. the country saw an 11% increase in total enrolments until September 2019 compared to the previous year. Many of its cities, including Melbourne, Sydney, Perth, Brisbane, and Adelaide, have been ranked best for international students. Like other countries, to study in Australia, International students require a student visa to study there. The study visa allows international students to work up to 40 hours every two weeks.</p>
      <div  className='d-flex justify-content-between'>
    <button className='btn btn-primarys'>
    Know More <MdKeyboardDoubleArrowRight/>
    </button>
    
    
      </div>
      </div>
     </div>
     <div className='col-lg-6 text-center '>
    
    <div className='img-container'>
            <img src={Img3} alt='img' data-aos="fade-left"  className='img-zoom'/>
      
    </div>
     </div>
    </div>
        </div></div>
        <Vkv/>
        </>
  )
}

export default Recharge