import React  ,{useEffect} from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Logo1 from "../image/CL00027 (1).jpg";
import Logo2 from "../image/CL00087.jpg";
import Logo3 from "../image/CL00036.jpg";
import Logo4 from "../image/CL00070.jpg";
import Logo5 from "../image/CL0105.jpg";
import Forms from './Forms';
import AOS from "aos";
import "aos/dist/aos.css";
const settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const sliderItems = [
  {
    img: Logo1,
    alt: "Image 1",
    text: "It has been so many years working Pyramid E Services, and they are the masters of this Industry. They have an excellent Admission success ratio along with highly expert fleet of consultants who will help and guide the students in the whole process. I would highly recommend everyone to contact Pyramid E Services if you are planning to study abroad. Thank you!.",
    H2: "College National",
    P2: "Business owner",
  },
  {
    img: Logo2,
    alt: "Image 2",
    text: "Humber College has worked with Pyramid E-Services for over a decade.  In this time they have knowledgeably guided students through the process of coming to Humber and what to expect when they begin classes.  We look forward to another decade of collaboration..",
    H2: "Great Plains College",
    P2: "Working Professional",
  },
  {
    img: Logo3,
    alt: "Image 3",
    text: "Acadia University has been associated with Pyramid-E-Services since 2020 and is being promoted well through all the platforms whether online or offline events. We wish them all the very best for their undeterred recruitment efforts.",
    H2: "Humber College",
    P2: "Retired Employee",
  },
  {
    img: Logo4,
    alt: "Image 4",
    text: "College National’s cooperation with Pyramid E Services started in 2020 and since which, we have widely adopted the full series of Pyramid E Services marketing strategies that are riding on the backbone network of College National. Along the way, we are pleased with our partnership with Pyramid E Services , a diversified operator that provides stable and reliable network and productivity. With its outstanding management and executive teams, we are happy to work together and create high efficiency.",
    H2: "Acadia University",
    P2: "Housewife",
  },

];

const Testimonial = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <div className="container mt-5 mb-2">
      <Slider {...settings}>
        {sliderItems.map((item, index) => (
          <div key={index} className="testimonial-slide text-center"  data-aos="fade-right">
            <img src={item.img} alt={item.alt} className="img-fluids img-border mx-auto d-block" />
            <p className='p-test text-center'>{item.text}</p>
            <h4 className='h4-test text-center'>{item.H2}</h4>
            <span className='span-test'>{item.P2}</span>
          </div>
        ))}
      </Slider>
    </div>
    <Forms/>
    </>
  );
}

export default Testimonial;
