import React ,{useEffect} from 'react';
import LogoFooter from "../image/logo-img-removebg-preview.png" 
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { IoLogoGoogleplus } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
function Footer() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className='footer-back'>
      <footer id="footer">
<div className="footer-top">
  <div className="container">
    <div className="row">

      <div className="col-lg-3 col-md-6 footer-contact">
        <h3>Nishan Consultants</h3>
        <p>
      
     <h6> HeadQuarters:</h6>

<p>6-A, Near AGI Building, Hotel Residency Road, Main Bus Stand-Jalandhar.</p>
       
          <strong>Phone:</strong> +1(437)981-0767<br/>
          <strong>Email:</strong>  info@example.com<br/>
        </p>
      </div>

      <div className="col-lg-3 col-md-6 footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li><IoIosArrowForward className="bx bx-chevron-right"/> <a href="/" className='text-decoration-none'>Home</a></li>
          <li><IoIosArrowForward className="bx bx-chevron-right"/> <a href="/"  className='text-decoration-none'>About us</a></li>
          <li><IoIosArrowForward className="bx bx-chevron-right"/> <a href="/"  className='text-decoration-none'>Services</a></li>
          <li><IoIosArrowForward className="bx bx-chevron-right"/> <a href="/"  className='text-decoration-none'>Contact</a></li>

        </ul>
      </div>

      <div className="col-lg-3 col-md-6 footer-links">
        <h4>Our Services</h4>
        <ul>
          <li><IoIosArrowForward className="bx bx-chevron-right"/> <a href="/"  className='text-decoration-none'>Emergency Roadside Assistance</a></li>
          <li><IoIosArrowForward className="bx bx-chevron-right"/> <a href="/"  className='text-decoration-none'>Cost Reduction Data</a></li>
          <li><IoIosArrowForward className="bx bx-chevron-right"/> <a href="/"  className='text-decoration-none'>Fleet Maintenance</a></li>
          <li><IoIosArrowForward className="bx bx-chevron-right"/> <a href="/"  className='text-decoration-none'>Tire Repair</a></li>

        </ul>
      </div>

      <div className="col-lg-3 col-md-6 footer-links">
        <h4>Our Social Networks</h4>
        <p className='text-justify'>Join our exclusive social network for the latest in fleet maintenance strategies</p>
     
      </div>

    </div>
  </div>
</div>


</footer>

</div>
    <footer className="footer mt-5 py-3 bg-light">
    <div className="container text-center">
      <div className="row">
        <div className="col-md-4 mb-3 " >
          <img src={LogoFooter} alt=" Logo" className="footer-logo " style={{width:"90px"}} />
        </div>
        <div className="col-md-4 mb-3 mb-md-0 mt-4 mb-2 " >
          <div>© 2024 Nishan Consultants</div>
          <a href="#privacy" >Privacy Policy</a><br/>
          <a href="#terms" >Terms & Conditions</a>
        </div>
        <div className="col-md-4 d-flex gap-4 mt-4 mb-2 instagrams" >
          <a href="#instagram" className="text-muted ">
            <FaInstagram className="fab fa-instagram fs-5"/>
          </a>
          <a href="#facebook" className="fa-facebook ">
            <FaFacebook className="fab  fs-5"/>
          </a>
          <a href="#twitter" className="fa-twitter ">
            <FaTwitter className="fab  fs-5"/>
          </a>
          <a href="#google-plus" className="fa-google-plus">
            <IoLogoGoogleplus className="fab  fs-5"/>
          </a>
        </div>
      </div>
    </div>
  </footer>
  </>
  );
}

export default Footer;
