import React  , {useEffect} from 'react'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Logo1 from "../image/CL00019.jpg"
import Logo2 from "../image/CL00027.jpg"
import Logo3 from "../image/CL00087.jpg"
import Logo4 from "../image/CL0125.jpg"
import Logo5 from "../image/CL0130.jpg"
import Logo6 from "../image/CL0152.jpg"
import Logo7 from "../image/CL0191.jpg"
import Logo8 from "../image/CL0228.jpg"
import Logo9 from "../image/CL0192.jpg"
import Slider from "react-slick";
import Testimonial from './Testimonial';

import AOS from "aos";
import "aos/dist/aos.css";
const settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true, // Hide arrows
  responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true
          }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 576,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      }
  ]
};

const sliderItems = [
  {
      img: Logo1,
      alt: "Image 1",
  },
  {
      img: Logo2,
      alt: "Image 2",
  },
  {
      img: Logo3,
      alt: "Image 3",
  },
  {
      img: Logo4,
      alt: "Image 4",
  },
  {
      img: Logo5,
      alt: "Image 5",
  },
  {
    img: Logo6,
    alt: "Image 6",
},
{
  img: Logo7,
  alt: "Image 7",
},
{
  img: Logo8,
  alt: "Image 8",
},
{
  img: Logo9,
  alt: "Image 9",
}
];
const Associates = () => {
    useEffect(() => {
        AOS.init();
      }, []);
  return (
    <>
    <section className="bg-img">
    <div className='container mt-5 mb-2'>
        <div className='row'>
            <h2 className='text-center mt-5 mb-2 font-bolds' data-aos="fade-up" >Institution Testimonials</h2>
      
    
    <Slider {...settings}>
 
        {sliderItems.map((item, index) => (
            <div key={index} className="d-flex justify-content-center align-items-center" data-aos="fade-up" >
                <img src={item.img} alt={item.alt} className="img-fluids"/>
            </div>
        ))}
    </Slider>

</div>
        </div>
        </section>
      <Testimonial/>
        </>
  
  )
}

export default Associates