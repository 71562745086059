import React from "react";
import ImgAgent from "../image/education-1.png"
import ImgDistributor from "../image/education-2.png"
import ImgSuper from "../image/education-3.png"
import { RiNumber1 } from "react-icons/ri";
import { RiNumber2 } from "react-icons/ri";
import { RiNumber3 } from "react-icons/ri";

import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";

const Associate = () => {
  return (
    <>
    <div className="container">
      <h4 className="text-center text-bolds fs-2">Education Loan Assistance
      </h4>
      <p>Thousands of Indian students go abroad every year to study in one of the top universities and colleges. As per the ministry of external Affairs – Government of India, until July 2019, around 10.9 Lakh Indian students were studying abroad, and their number is increasing rapidly. However, not all aspiring students are able to study overseas, as this requires a substantial amount of financial backing. For them, it is not the end of the road; they too can fulfill their dream of studying abroad by getting an overseas education loan. Today, a number of banks and lenders offer loans to students for higher education abroad.</p>
      <div className="row"  style={{background:"#eff9ff" ,padding:"10px"}}>
     
        <div className="col-lg-6">
          <p>
          There are several other public and private sector banks as well as lenders that provide overseas education loans to students. Education Loan usually covers:
          </p>
          

      
          <ul>
            <li>Tuition Fees</li>
            <li> Examination, library, laboratory, and hostel charges</li>
            <li>Cost of purchasing books, equipment, instruments, and uniform</li>
            <li>The best user interface for all important actions</li>
            <li> Travel expenses for study abroad</li>
            <li>Single wallet for all the services</li>
            <li>Works 24/7</li>
            <li> Caution money, refundable deposit, etc.</li>
          </ul>
        </div>
        <div className="col-lg-6">
          <div>
            <img src={ImgAgent} alt="img" className="ImgSuper px-4 "  />
            <div  className='d-flex justify-content-between mt-4 mb-2 px-4'>
    <button className='btn btn-primary'>
    Become A Retailer <MdKeyboardDoubleArrowRight/>
    </button>
    
      </div>
          </div>
        </div>
      </div>
      <hr/>
    </div>
   
    <div className="container">
      <div className="row  mt-4 mb-2" >
       
        <div className="col-lg-6">
          <div>
            <img src={ImgDistributor} alt="img"  className="ImgSuper px-4 " />
            <div  className='d-flex justify-content-between mt-4 mb-2 px-4'>
    <button className='btn btn-primary'>
    Become A Distributor <MdKeyboardDoubleArrowRight/>
    </button>
    
      </div>
          </div>
        </div>
        <div className="col-lg-6">
        <h2 className="review">Study Abroad Loan Eligibility Requirements </h2>
          <p>
          Mostly, lenders have their criteria for loan's upper limit, interest rate, period, and repayment mode, and their decision may also be based on the quality of the institution, relevancy of the course taken, and creditworthiness of the guarantor. Some Banks only issue education loans to study at an overseas education institution that they recognize. Here are some general eligibility requirements/conditions for Indian students to receive an education loan from banks.
          </p>
      
          <ul>
            <li>
            The applicant must be Indian</li>
            <li>Assign agents to different categories based on the business volume
            </li>
            <li>The applicant must have a good academic record</li>
            <li>The best user interface for all important actions</li>
            <li>Control panel to manage all the registered agents</li>
            <li>Accounting reports at the click of a button</li>
            <li>Product training and marketing materials</li>
            <li>All services under one single wallet</li>

          </ul>
          <p>Become a Distributor and earn up-to 50000 every month by just onboarding just 25 active agents.</p>
        </div>
    
      </div>
      <hr/>
    </div>
   
    <div className="container">
      <div className="row mt-5 mb-2"  style={{background:"#eff9ff" ,padding:"10px"}}>
        <h2 className="review">Documents Required for a Study Abroad loan </h2>
        <div className="col-lg-6">
          <p>
          Documents Required for a Study Abroad loan
          </p>
          <h5 className="font-bolds">Some of the unique features:</h5>
          <ul>
            <li>Earn extra income as commission on every service</li>
            <li>Easy, Fast & Secure</li>
            <li>Manage all services in one click</li>
            <li>The best user interface for all important actions</li>
            <li>Track business growth with detailed reports</li>
            <li>Single wallet for all the services</li>
            <li>Works 24/7</li>
            <li>Customer care team to assist in all queries</li>
          </ul>
        </div>
        <div className="col-lg-6">
          <div>
            <img src={ImgSuper} alt="img" className="ImgSuper px-4 " />
            <div  className='d-flex justify-content-between mt-4 mb-2 px-4'>
    <button className='btn btn-primary'>
    Become A Super Distributor <MdKeyboardDoubleArrowRight/>
    </button>
    
      </div>

          </div>
        </div>
      </div>
    </div>

    <section className='bg10 mt-5 mb-2'>
 <div className="container ">
      <div className="card p-5 shadow-sms mt-4 mb-2 " data-aos="fade-right">
        {/* <h2 className="text-center mb-4 font-bolds" >Earn Up-To 50,000 Every Month</h2> */}
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12 mt-2 mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Name" />
            </div>
            <div className="form-group col-md-12 mt-2  mb-2" >
              <input type="email" className="form-controls px-2 py-2" placeholder="Email Address" />
            </div>
            <div className="form-group col-md-12  mt-2  mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Mobile Number" />
            </div>
          </div>
          <div className="form-row  ">
            <div className="form-group col-lg-12 mt-2  mb-2">
              <select className="form-controls px-2 py-2">
                <option value="Retailer">Retailer</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>
            <div className="form-group col-md-12 mt-2  mb-2">
              <button type="submit" className="btn btn-primarys ">Join Now <MdKeyboardDoubleArrowRight/></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </section>
    </>
  );
};

export default Associate;
