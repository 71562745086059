import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from '../image/logo-img-removebg-preview.png';
import HeroSection from './HeroSection';
import { Nav, Offcanvas, Dropdown } from 'react-bootstrap';
function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {['sm'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3 fixed-top custom-navbar">
          <Container>
            <Navbar.Brand href="#">
              <img src={Logo} alt="logo-img"   className='logoimage'  />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src={Logo} alt="logo-img" style={{width:"65px"}} />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/About">About</Nav.Link>
                  <Dropdown
          show={showDropdown}
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          <Dropdown.Toggle as={Nav.Link} id="services-dropdown">
            Services
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* <Dropdown.Item href="/Travel">Travel</Dropdown.Item> */}
            <Dropdown.Item href="/DMT">Spouse Visa</Dropdown.Item>
            <Dropdown.Item href="/IRCTC">Pre-departure guidance</Dropdown.Item>
            <Dropdown.Item href="/UtilityService">IELTS & Spoken English</Dropdown.Item>
            <Dropdown.Item href="/AEPS">Pathway Program</Dropdown.Item>
          

          </Dropdown.Menu>
        </Dropdown>
                  <Nav.Link href="Associates">Associates</Nav.Link>
                  <Nav.Link href="Testimonials">Testimonials</Nav.Link>
                  {/* <Nav.Link href="Gallery">Gallery</Nav.Link> */}
                  <Nav.Link href="Contact">Contact</Nav.Link>
                </Nav>
                {/* <a href="/" className="btn quadmenu-text">Agent Login</a> */}
            
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      <HeroSection/>
    </>
  );
}

export default Header;
