import React from "react";
import AEPSimg from "../../image/pathway.jpg"
import AEPSimg1 from "../../image/ufv.jpg"
import AEPSimg4 from "../../image/university_west.jpg"
import AEPSimg3 from "../../image/yorkville.jpg"


import { FaStarOfLife } from "react-icons/fa6";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const AEPS = () => {
  return (
    <>
    <div className="container mt-5">
      <h4 className="text-center fs-1 text-bolds">Canada Pathway Programs</h4>
      <br/>
      <p>Pyramid Group's integral part Pyramid College of Business and Technology offers unique Pathway Programs for International Studies. Students initially learn at Pyramid College and then later get transferred to a foreign university or college with up to 100% credit transfer.

Additionally, the student has the option to complete the entire course at Pyramid College of Business and Technology under IKG PTU.</p>
      <div className="row" style={{background:"#eff9ff" ,padding:"10px"}}>
        <div className="col-lg-6">
         
            <img src={AEPSimg} alt="img"  className=" style-img" />
        
        </div>
        <div className="col-lg-6 ">
        <div>
            <h2 className="text-center fs-1 font-bolds">Other benefits
            </h2>
<ul>
  <p className="d-flex gap-2"> <FaStarOfLife className=" review fs-6"/>Safe & Secure system</p>
  <p className="d-flex gap-2"> <FaStarOfLife className=" review fs-6"/>No need to carry Debit or Credit card</p>
  <p className="d-flex gap-2"> <FaStarOfLife className=" review fs-6"/>Easily withdraw money, without visiting bank or ATM</p>

  <p className="d-flex gap-2"> <FaStarOfLife className=" review fs-6"/>
  Only Aadhaar number and fingerprint authentication required</p>
  <p className="d-flex gap-2"> <FaStarOfLife className=" review fs-6"/>Massive savings of up to $ 40,000 * on international studies</p>
  <p className="d-flex gap-2"> <FaStarOfLife className=" review fs-6"/>
  More likely to be accepted by a foreign institution</p>
<p className="d-flex gap-2"> <FaStarOfLife className=" review fs-6"/>

Post Graduate Work Permit Eligible Program *</p>
<p className="d-flex gap-2"> <FaStarOfLife className=" review fs-6"/>

Study Program as per Foreign Institution Standards*</p>
<p className="d-flex gap-2"> <FaStarOfLife className=" review fs-6"/>

Zero File Application Fee*</p>




</ul>
          </div>
        </div>
    
      </div>

      <div className="row mt-4 mb-2">
      <div className="col-lg-6  ">
          <div>
            <h2 className="text-center fs-1 font-bolds">University of the Fraser Valley, Canada</h2>
<p>The University of the Fraser Valley is a fully accredited, public university located in the beautiful Fraser Valley of Vancouver, British Columbia, where around 15,000 students study. UFV has campuses and locations in Abbotsford, Chilliwack, Mission, Hope, Agassiz, and a growing presence in Chandigarh, India.</p>
        <br/>
        <p><h6>Bachelors in Computer Information Systems</h6>
        Designed to provide a mixture of theory and hands-on applied knowledge, BCIS can help pave your way for a lucrative career in a variety of fields that include: Software development; Systems analysis and design; Web and database design, and programming; User interface design; Project Management; Network design and administration.</p>
          </div>
        </div>
        <div className="col-lg-6">
          
    
            <img src={AEPSimg1} alt="img"  className=" style-img"  />
      
        </div>
     
    
      </div>

    </div>
    <div className="container mt-4">
    
      <div className="row" style={{background:"#eff9ff" ,padding:"10px"}}>
        <div className="col-lg-6">
       
            <img src={AEPSimg3} alt="img" className=" style-img"  />
      
        </div>
        <div className="col-lg-6 ">
        <div>
            <h2 className="text-center fs-1 font-bolds">Yorkville University
            </h2>
<p>Established in the year 2003 in New Brunswick, Canada, Yorkville University offers professional, practitioner-oriented masters and bachelor degree programs through its campuses in Fredericton - New Brunswick, Toronto – Ontario, and Vancouver - British Columbia. The University offers flexible degree programs designed to equip students with professional knowledge and skills.</p>
     <br/>
     <p><h4>Bachelors in Business Administration (BBA)
  </h4>   The Bachelors in Business Administration program is designed to develop students' administrative and business capabilities through innovative and practical techniques. This 4-year program comes with specialization options that include Project Management, Energy Management, Accounting, and Supply Chain Management.</p>
          </div>
        </div>
    
      </div>

      <div className="row mt-4 mb-2">
      <div className="col-lg-6  ">
          <div>
            <h2 className="text-center fs-1 font-bolds">University Canada West</h2>
<p>Located in the heart of vibrant Vancouver, University Canada West is a contemporary business-oriented, teaching-intensive independent university that offers a range of career-focused programs. UCW's downtown campus is within close proximity to many of the world's largest companies, giving students unparalleled access to a network of potential employers.</p>
        <br/>
        <p><h6>Bachelors in Computer Information Systems</h6>
        Master of Business Administration (MBA)
        The Master of Business Administration (MBA) program is designed to prepare students for leadership on the global stage. the program is two and a half years in which focus is given to progressively develop students' skills so that they can handle the most complex problems.</p>
          </div>
        </div>
        <div className="col-lg-6">
          
          <div className="">
            <img src={AEPSimg4} alt="img" className=" style-img"  />
          </div>
        </div>
     
    
      </div>
      
    </div>
    <section className='bg10 mt-5 mb-2'>
 <div className="container ">
      <div className="card p-5 shadow-sms mt-4 mb-2 " data-aos="fade-right">
        {/* <h2 className="text-center mb-4 font-bolds" >Earn Up-To 50,000 Every Month</h2> */}
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12 mt-2 mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Name" />
            </div>
            <div className="form-group col-md-12 mt-2  mb-2" >
              <input type="email" className="form-controls px-2 py-2" placeholder="Email Address" />
            </div>
            <div className="form-group col-md-12  mt-2  mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Mobile Number" />
            </div>
          </div>
          <div className="form-row  ">
            <div className="form-group col-lg-12 mt-2  mb-2">
              <select className="form-controls px-2 py-2">
                <option value="Retailer">Retailer</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>
            <div className="form-group col-md-12 mt-2  mb-2">
              <button type="submit" className="btn btn-primarys ">Join Now <MdKeyboardDoubleArrowRight/></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </section>
           
</>
  );
};

export default AEPS;
