import React from "react";
import { FaStarOfLife } from "react-icons/fa6";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import AEPSimg from "../../image/predeparture-1.png"
import AEPSimg1 from "../../image/predeparture-2.png"

const CTC = () => {
  return (
    <div>
      <>
        <div className="container">
          <h4 className="text-center fs-1 font-bolds">Pre-departure Support</h4>
          <div className="row" style={{background:"#eff9ff" ,padding:"10px"}}>
            <div className="col-lg-6">
              <div className="">
                <img src={AEPSimg} alt="img" className=" style-img" />
              </div>
            </div>
            <div className="col-lg-6  shadow-AEP">
              <div>
               
               
                  <p>
                 
Pyramid eServices is committed to providing you with all the assistance you need to make your study abroad journey memorable. Undoubtedly, packing and flying to your dream destination will be one of the most exciting experiences for you, and we make it even more pleasant by providing you with all the necessary information required to make well-informed decisions once you get there.

While travelling abroad or even after that, there will be many things of which if you have information in advance, your overall experience will be fabulous. Pyramid eServices, through Pre-departure sessions, provide you with all this information. The sessions include information o
                  </p>
                
              </div>
            </div>
          </div>

          <div className="row mt-4 mb-2">
            <div className="col-lg-6 shadow-AEP">
              <div>
                <h2 className="text-center fs-1 font-bolds">
                Pyramid's Student Support Community
                </h2>
                <p>
                When you apply through Pyramid eServices, you become an elite member of our Facebook Connect community, an important resource for receiving specific support on your institution, accommodation, transportations, etc., from all of our students who have received admissions through us and are now abroad. In addition to this, through this medium, our expert team stays in touch with you if, for any reason, you need our support.

Indeed, our relationship with you does not end merely with providing you with your admission. It's a relationship of trust that lasts long, aiming to provide you with the best admission service. Please call us at 92563-92563 or visit any of our nearest branches to fullfil your dream to study Abroad.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <img src={AEPSimg1} alt="img" className=" style-img" />
              </div>
            </div>
          </div>
        </div>
        <section className='bg10 mt-5 mb-2'>
 <div className="container ">
      <div className="card p-5 shadow-sms mt-4 mb-2 " data-aos="fade-right">
        {/* <h2 className="text-center mb-4 font-bolds" >Earn Up-To 50,000 Every Month</h2> */}
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12 mt-2 mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Name" />
            </div>
            <div className="form-group col-md-12 mt-2  mb-2" >
              <input type="email" className="form-controls px-2 py-2" placeholder="Email Address" />
            </div>
            <div className="form-group col-md-12  mt-2  mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Mobile Number" />
            </div>
          </div>
          <div className="form-row  ">
            <div className="form-group col-lg-12 mt-2  mb-2">
              <select className="form-controls px-2 py-2">
                <option value="Retailer">Retailer</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>
            <div className="form-group col-md-12 mt-2  mb-2">
              <button type="submit" className="btn btn-primarys ">Join Now <MdKeyboardDoubleArrowRight/></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </section>
      </>
    </div>
  );
};

export default CTC;
