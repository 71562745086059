import React, { useState } from 'react';
import Img from "../image/vipul.png"
import Img2 from "../image/Vinay.png"
import { IoAddOutline } from "react-icons/io5";
import Associates from '../Components/Associates';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Logo1 from "../image/BOB-Logo.png"
import Logo2 from "../image/NPCI-Logo.png"
import Logo3 from "../image/Redbus-Logo.png"
import Logo4 from "../image/Dish-TV-Logo.png"
import Logo5 from "../image/Videocon-Logo.png"
import Logo6 from "../image/ICICI-Bank-Logo.png"
import Logo7 from "../image/Cleartrip-Logo-1024x222.png"
import Logo8 from "../image/Tata-Sky-Logo.png"
import Logo9 from "../image/Yes-Bank.png"
import Slider from "react-slick";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
const About = () => {
    const [isHoveredMission, setIsHoveredMission] = useState(false);
    const [isHoveredVision, setIsHoveredVision] = useState(false);
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: true, // Hide arrows
      responsive: [
          {
              breakpoint: 1024,
              settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  infinite: true,
                  dots: true
              }
          },
          {
              breakpoint: 768,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
              }
          },
          {
              breakpoint: 576,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
              }
          },
          {
              breakpoint: 480,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
              }
          }
      ]
    };
    
    const sliderItems = [
      {
          img: Logo1,
          alt: "Image 1",
      },
      {
          img: Logo2,
          alt: "Image 2",
      },
      {
          img: Logo3,
          alt: "Image 3",
      },
      {
          img: Logo4,
          alt: "Image 4",
      },
      {
          img: Logo5,
          alt: "Image 5",
      },
      {
        img: Logo6,
        alt: "Image 6",
    },
    {
      img: Logo7,
      alt: "Image 7",
    },
    {
      img: Logo8,
      alt: "Image 8",
    },
    {
      img: Logo9,
      alt: "Image 9",
    }
    ];
    const handleMouseEnterMission = () => {
      setIsHoveredMission(true);
    };
  
    const handleMouseLeaveMission = () => {
      setIsHoveredMission(false);
    };
  
    const handleMouseEnterVision = () => {
      setIsHoveredVision(true);
    };
  
    const handleMouseLeaveVision = () => {
      setIsHoveredVision(false);
    };
  return (
    <section style={{background:"#eff9ff" ,padding:"10px"}}>
    <div className='container'>
<div>
    <h4 className='text-center'>Study in USA

</h4>
<h6>Application Process, College, Courses, and Universities</h6>
<p>

The United States is a world-famous study destination with hundreds of prestigious universities and colleges offering high-quality degrees and diplomas recognized by most employers worldwide. The USA's flexible education system, multicultural environment, and ample career opportunities are some of the most prominent reasons that attract students from all over the world to this beautiful country to study at both the undergraduate and master's levels. US universities offer many research programs which open up the gates for brilliant career options for students. Here are some more reasons why international students choose the USA as their study destination.</p>
<h6>Requirements to Study in USA</h6>
<p>The US embassy primarily offers three types of Student visas: F1, M1, and J1. Most probably, the visa type you will need would be an F1 student visa. F1 Visa allows international students to complete their academic studies in the USA. M1 is for non-academic or vocational courses, and the J1 is for those international students who want to participate in the government-recognized cultural exchange programs..</p>
<h6>How to Apply for Study Visa for USA</h6>
<p>Almost every International Student wants to do a part-time job while studying. Due to day-to-day expenses, it has become necessary for students to work along with their studies. During the part-time job, students learn many things like Time Management, Work culture, etc. Students can find part-time jobs like Payroll Administrator, Part Time Advisor, Faculties Support and Care Assistant, etc.</p>
<div>
  <h6>Work while Study in USA</h6>
  <p>Part-Time work opportunities in the US can help you to earn enough to meet your daily expenses. It is also a great way to build a professional network and familiarise yourself with the American work culture. Although working part-time may not be enough to support entirely, it can certainly help to lower the cost. Internship work experience will undoubtedly look good in your resume.</p>
<p>However, you can't directly go out and get any desirable job as an international student. There are some rules that students must follow. Before you start finding a job in the USA as a student, contact your Designated School Official (DSO). Then, DSO will guide you throughout the procedure of applying for a Social Security Number, which is required for all students working in the US, and help you through various steps.</p>
</div>
</div>



<section className='bg10 mt-5 mb-2'>
 <div className="container ">
      <div className="card p-5 shadow-sms mt-4 mb-2 " data-aos="fade-right">
        {/* <h2 className="text-center mb-4 font-bolds" >Earn Up-To 50,000 Every Month</h2> */}
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12 mt-2 mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Name" />
            </div>
            <div className="form-group col-md-12 mt-2  mb-2" >
              <input type="email" className="form-controls px-2 py-2" placeholder="Email Address" />
            </div>
            <div className="form-group col-md-12  mt-2  mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Mobile Number" />
            </div>
          </div>
          <div className="form-row  ">
            <div className="form-group col-lg-12 mt-2  mb-2">
              <select className="form-controls px-2 py-2">
                <option value="Retailer">Retailer</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>
            <div className="form-group col-md-12 mt-2  mb-2">
              <button type="submit" className="btn btn-primarys ">Join Now <MdKeyboardDoubleArrowRight/></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </section>
   
    </div>
    </section>
  )
}

export default About