import React from 'react'
import  Img from "../image/test.jpg"
import  Img2 from "../image/test2.jpg"
import  Img3 from "../image/test3.jpg"
import  Img4 from "../image/test4.jpg"
import  Img1 from "../image/1-180x180.jpg"

import { MdKeyboardDoubleArrowRight } from "react-icons/md";



const Testimoniales = () => {
  return (
    <>
  <section>
    <div style={{background:"#eff9ff" ,padding:"20px"}}> 
      <div className='container'>
        <div className='row d-flex gap-5 align-items-center justify-content-center'>
          <div className='col-lg-6 testimonial-card'  data-aos="fade-right">
            <div>
              <div className='text-center'>
                                     

              It's been an incredible journey with Pyramid eServices. Their exceptional experience and support truly set them apart. After completing my file work, I was amazed by their efficiency in facilitating my process to pursue an MBA program at a university in Canada. Their professionalism is commendable. Thank you for paving the way for my academic endeavors.
                                    
                                    .</div>
              <div className='d-flex gap-2 align-items-center justify-content-center mt-4 mb-2'>
                <img src={Img1} alt='Suresh' className='test-img' />
                <div>
                  {/* <h5>Archna Chugh</h5> */}
                  <p>Working Professional</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 testimonial-card'  data-aos="fade-left">
            <div>
              <div className='text-center'>
                                        Thank you for swiftly processing my application; it was an unbelievable experience, and I am extremely satisfied. I highly recommend Pyramid eServices to all of you. The Pyramid is a One-stop solution for overseas study aspirants. They are experienced and dedicated to the careers of students who come to them. Thank you again for making my dream come true to study in Canada.
                                    .</div>
              <div className='d-flex gap-2 align-items-center justify-content-center mt-4 mb-2'>
                <img src={Img2} alt='Mr Bhupati' className='test-img' />
                <div>
                  <h5>Mr Bhupati</h5>
                  <p>Retired Employee</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className='container'>
        <div className='row mt-5 mb-2 d-flex gap-5 align-items-center justify-content-center'>
          <div className='col-lg-6 testimonial-card'  data-aos="fade-right">
            <div>
              <div className='text-center'>
                                        Hello, I am Vansh Sundan from Jammu. I want to thank the Pyramid eServices team who, despite my previous refusal, helped me in getting admission into the prestigious Lakehead University of Canada. I would like to recommend all the students that they, at least once, visit pyramid eServices. My experience with them was terrific.
                                    </div>
              <div className='d-flex gap-2 align-items-center justify-content-center mt-4 mb-2'>
                <img src={Img3} alt='Suresh' className='test-img' />
                <div>
                  <h5>	
                  Vansh Sundan
</h5>
                  <p>Housewife</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 testimonial-card'  data-aos="fade-left">
            <div>
              <div className='text-center'>
                                     
              Study abroad guidance matters, and I am genuinely grateful to pyramid eServices that managed my file expertly. I had two previous refusals, but they cleared my way to study in Canada. I would like to recommend Pyramid eServices to all of you and say DO NOT waste your time with other consultants, apply through Pyramid eServices, and save your time and money. They know their work.
                                    
                                    </div>
              <div className='d-flex gap-2 align-items-center justify-content-center mt-4 mb-2'>
                <img src={Img4} alt='Mr Bhupati' className='test-img' />
                <div>
                  {/* <h5>Nikhil
                  </h5> */}
                  <p>College student</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{background:"#eff9ff" ,padding:"10px"}}>


     
      <div className='container'>
        <div className='row mt-5 mb-2 d-flex gap-5 align-items-center justify-content-center'>
          <div className='col-lg-12 testimonial-card'  data-aos="fade-up">
            <div>
              <div className='text-center'>
                                        Hello, my name is Jatinder Kaur, and I am from Batala. What is a true visa service? I only understood after coming to Pyramid eServices. Earlier my visa got refused from two consultants.  Now, I am set to pursue my studies at Cegep College, Canada. Their efficiency and dedication were evident in the swift processing of my application.
                                    </div>
              <div className='d-flex gap-2 align-items-center justify-content-center mt-4 mb-2'>
                <img src={Img} alt='Rajesh' className='test-img' />
                <div>
                  <h5>	
	
                  Jatinder Kaur
</h5>
                  <p>Business owner</p>
                </div>
              </div>
            </div>
          </div>
      
        </div>
      </div>
      </div>
      <section className='bg10 mt-5 mb-2'>
 <div className="container ">
      <div className="card p-5 shadow-sms mt-4 mb-2 " data-aos="fade-right">
        {/* <h2 className="text-center mb-4 font-bolds" >Earn Up-To 50,000 Every Month</h2> */}
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12 mt-2 mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Name" />
            </div>
            <div className="form-group col-md-12 mt-2  mb-2" >
              <input type="email" className="form-controls px-2 py-2" placeholder="Email Address" />
            </div>
            <div className="form-group col-md-12  mt-2  mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Mobile Number" />
            </div>
          </div>
          <div className="form-row  ">
            <div className="form-group col-lg-12 mt-2  mb-2">
              <select className="form-controls px-2 py-2">
                <option value="Retailer">Retailer</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>
            <div className="form-group col-md-12 mt-2  mb-2">
              <button type="submit" className="btn btn-primarys ">Join Now <MdKeyboardDoubleArrowRight/></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </section>
    </section>
    </>
  )
}

export default Testimoniales