import React ,{useEffect} from 'react'
import Recharge from './Recharge'
import Img2 from "../image/banner-f.png"
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaRegCircleDot } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";
const FinancialServices = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <div style={{background:"#eff9ff" ,padding:"10px"}}>  <div className='container mt-5 mb-2' >
    <div className='row'>
    <div className='col-lg-6  mt-4 mb-2 '>
    
    <div className='img-container'>
            <img src={Img2} alt='img' data-aos="fade-right"  className='img-zoom'/>
      
    </div>
     </div>
     <div className='col-lg-6 mt-4 mb-2 ' data-aos="fade-left">
      <div >
      <h5 className='d-flex gap-3 font-bolds'>
      <span><FaRegCircleDot className='fs-2 colors'/></span>
      The USA Study Visa</h5>
      <br/>
      <p className='text-center'>The USA, famous for its excellent education systems, is among the top countries where tens of thousands of international students go with a dream to study in leading universities and colleges. The US offers three types of study visas under the F, J, and M categories. Most international students apply for the F-1 visa, which is intended for academic purposes. Some students submit their applications under M-1 or J-1 visa type that allows them to pursue non-academic/professional courses or participate in an exchange program, respectively..</p>
      <div  className='d-flex justify-content-between text-center'>
    <button className='btn btn-primarys'>
    Join Now <MdKeyboardDoubleArrowRight/>
    </button>
 
 
      </div>
      </div>
     </div>
    </div>
        </div></div>
        <Recharge/>
        </>

  )
}

export default FinancialServices