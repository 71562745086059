import React ,{useEffect} from 'react'
import Business from './Business'
import Img1 from "../image/predeparture-2.png"
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaRegCircleDot } from "react-icons/fa6";

import AOS from "aos";
import "aos/dist/aos.css";
const B2C = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <div>   <div className='container mt-5 mb-2'>
    <div className='row'>
    
    
     <div className='col-lg-6  '>
    
    <div className='img-container'>
            <img src={Img1} alt='img'  data-aos="fade-right" className='img-zoom'/>
      
    </div>
     </div>
     <div className='col-lg-6' data-aos="fade-left">
      <div >
      <h5 className='d-flex gap-3 font-bolds'>
        <span><FaRegCircleDot className='fs-2 colors'/></span>
        Germany Study Visa
      </h5>
      <br/>
      <p>Germany is not only famous for innovation and advancement in the automobile and pharmaceutical sectors but also for its world-class institutes that provide excellence and thousands of internationally recognized study programs at low tuition fees. Understanding the International students' needs, german institutions offer several international standard programs in English medium. For some programs, international students do not even require to take IELTS, although they have to fulfill the other requirements associated with a German student visa application.</p>
      <div  className='d-flex justify-content-between'>
    <button className='btn btn-primarys'>
    Know More <MdKeyboardDoubleArrowRight/>
    </button>
    
    
      </div>
      </div>
     </div>
    </div>
        </div></div>
        <Business/>
        </>
  )
}

export default B2C