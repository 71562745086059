import React from 'react';
import Header from './Components/Headers';
import Footer from './Components/Footer';
import HeroSlider from './Components/HeroSlider';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router , Routes , Route } from 'react-router-dom';
import About from './Pages/About';
// import Services from './Pages/Services';
import Associate from "./Pages/Associate"
import Testimoniales from './Pages/Testimoniales';
import Contact from "./Pages/Contact"
import Travel from './Pages/Services/Travel';
import DMT from "./Pages/Services/DMT"
import CTC from "./Pages/Services/CTC"
import UtilityServices from "./Pages/Services/UtilityServices"
import AEPS from './Pages/Services/AEPS';

function App() {
  return (
    <div className="App">
      <Header />
      <Router>

      <Routes>
    <Route path='/' element={ <HeroSlider />}/>
    <Route path='About' element={<About/>}/>
    <Route path='Associates' element={<Associate/>}/>
    <Route path='Testimonials' element={<Testimoniales/>}/>
    <Route path='Contact' element={<Contact/>}/>
    <Route path='Travel' element={<Travel/>}/>
    <Route path='DMT' element={<DMT/>}/>
    <Route path='IRCTC' element={<CTC/>}/>
    <Route path='UtilityService' element={<UtilityServices/>}/>
    <Route path='AEPS' element={<AEPS/>}/>














       
    
      </Routes>
      <Footer />
      </Router>
    </div>
  );
}

export default App;
