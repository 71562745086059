import React ,{useEffect} from 'react'
import Img6 from "../image/featured.png"
import FinancialServices from './FinancialServices'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaRegCircleDot } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";
const TravelBook = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
            <div className='container   mt-5 mb-2'>       
      <div className='row'>
      {/* <h2 className='font-bolds text-center '  data-aos="fade-up">
      Multiple Services Under One Umbrella</h2> */}
        <div className='col-lg-6 mt-4 mb-2 '>

    <div className='icon'  data-aos="fade-up">
        <h5 className='d-flex gap-3'>
        <span><FaRegCircleDot className='fs-2 colors'/></span>
        The UK Study Visa</h5>
    
</div>
<div>
    <p   data-aos="fade-up">With changes in post-study rights and the implementation of the new points-based immigration system, the United Kingdom has again become a hot destination for Indian students. The number of Indian students in the UK in 2018/19 grew up by a whopping 42%. Besides this, the UK's excellent education system, research opportunities, and opportunities to work after graduation are some of the reasons why international students not just from India but worldwide choose to study in the UK. The new points-based system has simplified the study visa process to a great extent.</p>
<br/>
<button className='btn btn-primarys'  data-aos="fade-up">Know More <MdKeyboardDoubleArrowRight/></button>
</div>
 </div>
 <div className='col-lg-6  text-center'>

<div className='img-container'>
        <img src={Img6} alt='img' data-aos="fade-left"  className='img-zoom'/>
  
</div>
 </div>
      </div>
   
    </div>
<FinancialServices/>
   


    </>
  )
}

export default TravelBook