import React ,{useEffect} from 'react'
import Img from "../image/Agent.jpg"
import Img2 from "../image/distributor.jpg"
import Img3 from "../image/super-distributor.jpg"
import Associates from './Associates'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import AOS from "aos";
import "aos/dist/aos.css";
const Business = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <div style={{background:"#eff9ff" ,padding:"10px"}}>
          <div className='container'>
          <h2 className='text-center font-bolds' data-aos="fade-up" >Study in USA</h2>
            <p className='text-center' data-aos="fade-up" > Application Process, College, Courses, and Universities

The United States is a world-famous study destination with hundreds of prestigious universities and colleges offering high-quality degrees and diplomas recognized by most employers worldwide. The USA's flexible education system, multicultural environment, and ample career opportunities are some of the most prominent reasons that attract students from all over the world to this beautiful country to study at both the undergraduate and master's levels. US universities offer many research programs which open up the gates for brilliant career options for students. Here are some more reasons why international students choose the USA as their study destination.</p>
        <div className='row'>
            
            <div className='col-lg-4 mt-4 mb-2' data-aos="fade-up">
            <div className='img-containers d-flex flex-column align-items-center custom-shadowes p-3'>
            <img src={Img} alt='img' width={100}   className='img-zooms'/>
            <h5 className='text-center'>Requirements to Study in USA</h5> 
            <p className='text-center'>The US embassy primarily offers three types of Student visas: F1, M1, and J1. Most probably, the visa type you will need would be an F1 student visa. F1 Visa allows international students to complete their academic studies in the USA. M1 is for non-academic or vocational courses, and the J1 is for those international students who want to participate in the government-recognized cultural exchange programs.</p>
            <ul>
            <p  className='text-center font-bolds'>
            High School Diploma and Transcripts</p>
            <p  className='text-center font-bolds'>Bank Letter with Adequate funds</p>
            <p  className='text-center font-bolds'>Affidavits of financial support</p>
            <p  className='text-center font-bolds'>Passport Copy</p>
            <p  className='text-center font-bolds'>Adequate funds for fee submission</p>
          
            </ul>
            <br/>
            <br/>
            
<button className='btn btn-primarys'>Join Now <MdKeyboardDoubleArrowRight/></button>


    </div>
            </div>
            <div className='col-lg-4 mt-4 mb-2' data-aos="fade-up">
            <div className='img-containers d-flex flex-column align-items-center custom-shadowes p-3'>
            <img src={Img2} alt='img' width={100}  className='img-zooms'/>
            <h5 className='text-center'>Quality Education</h5> 
            <p className='text-center'>The US is home to some of the best universities, which consistently rank high in world university rankings. For the year 2021, the ranking agencies QS and The Times Higher Education have chosen 160 and 181 American institutions, respectively, the highest than any other country, enough to show American educational institutions' excellence.</p>
           <ul>
            <p  className='text-center'>Attractive Income</p>
            <p  className='text-center'>Real Time Commission</p>
            <p  className='text-center'>Android Application</p>
            <p  className='text-center'>Single Wallet</p>
            <p  className='text-center'>Multiple Services</p>
      
            </ul>
            <br/>
            <br/>
            
<button className='btn btn-primarys'>Join Now <MdKeyboardDoubleArrowRight/></button>


    </div>
            </div>
            <div className='col-lg-4 mt-4 mb-2' data-aos="fade-up">
            <div className='img-containers d-flex flex-column align-items-center custom-shadowes p-3' >
            <img src={Img3} alt='img' width={100}   className='img-zooms'/>
            <h5 className='text-center'>	
            Flexible education system</h5> 
            <p className='text-center'>The American education system is highly flexible. It lets you choose not only your course content but also structure. Moreover, It is usually not required to declare a major until the end of your second year, which means you get enough time to explore your interest and choose your career path accordingly.</p>
          <ul>
            <p  className='text-center'>Attractive Income</p>
            <p  className='text-center'>Real Time Commission</p>
            <p  className='text-center'>Android Application</p>
            <p  className='text-center'>Single Wallet</p>
            <p  className='text-center'>Multiple Services</p>
         
            </ul>
            <br/>
            <br/>
            
<button className='btn btn-primarys'>Join Now <MdKeyboardDoubleArrowRight/></button>


    </div>
            </div>
        </div>

    </div>
    </div>
    <Associates/>
    </>
  )
}

export default Business