import React ,{useEffect} from 'react'
import B2C from './B2C'
import Img5 from "../image/predeparture-1.png"
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { IoBarChartOutline } from "react-icons/io5";
import AOS from "aos";
import "aos/dist/aos.css";
const Kiosk = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <div>     <div className='container mt-5 mb-2'>
    <div className='row'>
    
     <div className='col-lg-6 mt-4 mb-2 text-justify'>
      <div >
      <h5 className='d-flex gap-3 font-bolds' data-aos="fade-right">
     <span><IoBarChartOutline className='fs-2 colors'/></span>
    KIOSK BANKING</h5>
      <br/>
      <p data-aos="fade-right">When you apply through Pyramid eServices, you become an elite member of our Facebook Connect community, an important resource for receiving specific support on your institution, accommodation, transportations, etc., from all of our students who have received admissions through us and are now abroad. In addition to this, through this medium, our expert team stays in touch with you if, for any reason, you need our support.

Indeed, our relationship with you does not end merely with providing you with your admission. It's a relationship of trust that lasts long, aiming to provide you with the best admission service. Please call us at 92563-92563 or visit any of our nearest branches to fullfil your dream to study Abroad.</p>
      <div  className='d-flex justify-content-between'>
    <button className='btn btn-primarys'>
    Know More <MdKeyboardDoubleArrowRight/>
    </button>
    
      </div>
      </div>
     </div>
     <div className='col-lg-6  mt-2 mb-2 text-center'>
    
    <div className='img-container'>
            <img src={Img5} alt='img'  data-aos="fade-left" className='img-zoom'/>
      
    </div>
     </div>
    </div>
        </div></div>
        <B2C/>
        </>
  )
}

export default Kiosk