import React, { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "animate.css"; // Import Animate.css
import Img from "../image/usa-banner.jpg"
import Img2 from "../image/careers-banner.jpg"
import Img3 from "../image/event-enquiry.jpg"
import Img4 from "../image/trending-banner.jpg"
import Img5 from "../image/group-banner.jpg"
import Img6 from "../image/pre-departure-guidance-banner.jpg"

const Hero = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const sliderItems = [
        {
            img: Img,
            alt: "Image 1",
            text: "YOUR BRIGHT FUTURE IS OUR SUCCESS <br/> LET OUR EXPERTS FIND THE BEST FOR YOU"
        },
        {
            img: Img2,
            alt: "Image 2",
            text: "KAZAMBE OFFERS STUDY<br/>IN INDIA & ABROAD"
        },
        {
            img: Img3,
            alt: "Image 3",
            text: "EDUCATION BEYOND BOUNDARIES<br/>CHOOSE FROM THE WORLD RENOWNED INSTITUTES"
        },
        {
            img: Img4,
            alt: "Image 4",
            text: "BUILDING A BETTER FUTURE<br/> ONE STOP SERVICE CENTER FOR STUDENTS"
        },
        {
            img: Img5,
            alt: "Image 5",
            text: "Open Your Own Mini ATM"
        },
        {
            img: Img6,
            alt: "Image 6",
            text: "Start Your Own Tours & <br/>Travels Company"
        }
    ];

    useEffect(() => {
        const elements = document.querySelectorAll('.text-overlay h6, .text-overlay button');
        elements.forEach(el => {
            el.classList.remove('animate__animated', 'animate__fadeInUp', 'animate__fadeInDown');
            void el.offsetWidth; // trigger reflow
            el.classList.add('animate__animated');
            if (el.tagName === 'H6') {
                el.classList.add('animate__fadeInUp');
            } else if (el.tagName === 'BUTTON') {
                el.classList.add('animate__fadeInDown');
            }
        });
    }, [currentSlide]);

    return (
        <div className="home mb-5">
            <div className="swiper-container">
                <Slider {...settings}>
                    {sliderItems.map((item, index) => (
                        <div key={index} className="flex slider-item-container">
                            <div className="slider-item">
                                <img src={item.img} alt={item.alt} />
                                <div className="text-overlay">
                                    {/* <h6 dangerouslySetInnerHTML={{ __html: item.text }} className="TEXT"></h6> */}
                                 
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Hero;
