import React from 'react'
import { FaStarOfLife } from "react-icons/fa6";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import AEPSimg from "../../image/canada-spouse.png"
import AEPSimg1 from "../../image/uk-spouse.png"
import NewZealand from "../../image/newzealand-spouse.png"
import Australia from "../../image/australia-spouse.png"
import USA from "../../image/usa-spouse.png"
const DMT = () => {
  return (
    <div>
    <>
        <div className="container">
          <div className="row" style={{background:"#eff9ff" ,padding:"10px"}}>
            <div className="col-lg-6">
              <div className="">
                <img src={AEPSimg} alt="img" className=" style-img"   />
              </div>
            </div>
            <div className="col-lg-6  shadow-AEP">
              <div>
                <h2 className="text-center fs-1 font-bolds">Canada Spouse Visa</h2>
            <p>Canada's liberal immigration policy allows International students to bring their spouse, partner, or children with them. Your spouse or common-law partner may apply for an open work permit, which can be filled before or after your arrival in Canada. The Spouse Work Permit is usually issued for the same duration as per the students' study permit duration and allows the holder to work full time in Canada. If your partner also has plans to study in Canada, then he/she must apply for a separate study permit.</p>
              </div>
            </div>
          </div>
          </div>
          <div className="container  mt-4 mb-2">
          <div className="row">
            <div className="col-lg-6 shadow-AEP">
              <div>
                <h2 className="text-center fs-1 font-bolds">
                The UK Spouse Visa
                </h2>
              <p>Known for world-class universities and a multicultural environment, the UK allows international students to bring their family members or dependents along with them. Your partner will also be able to work in the UK If you are either a government-sponsored student taking a full-time course that is longer than six months or taking a full-time postgraduate course of 9 or more months at an approved institution. Your dependent can also apply at the same time when you apply for your study visa.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <img src={AEPSimg1} alt="img"  className=" style-img"/>
              </div>
            </div>
          </div>
          </div>
          <div className="container  mt-4 mb-2">
          <div className="row" style={{background:"#eff9ff" ,padding:"10px"}}>
        
            <div className="col-lg-6">
              <div className="">
                <img src={USA} alt="img" className=" style-img"   />
              </div>
            </div>
            <div className="col-lg-6 shadow-AEP">
              <div>
                <h2 className="text-center fs-1 font-bolds">
                The USA Spouse Visa
                </h2>
              <p>The USA, known for academic excellence, study programs, and research opportunities, authorizes international students to bring their beloveds along with them. If your partner is travelling with you, he or she must apply under the dependent category of your visa type. In other words, If you are applying under F-1 or M-1 visa, your partner will have to apply under an F-2 or M-2 visa as per your visa type. If you and your spouse decide to file your application together, you can schedule the visa interview together..</p>
              </div>
            </div>
          </div>
          </div>
          <div className="container  mt-4 mb-2">
          <div className="row">
            <div className="col-lg-6 shadow-AEP">
              <div>
                <h2 className="text-center fs-1 font-bolds">
                Australia Spouse Visa
                </h2>
              <p>Robust educational infrastructure, high quality of life, relatively lower expenses have made Australia a preferred destination for higher education. The government of Australia also allows international students to bring their family members with them. You can take your partner or dependent with you either by including them on your original student visa application or by applying for their visas once you have started your course in an Australian institution so that they can join you. Generally, a dependent can work in Australia, but it also depends on his/her Visa type..</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <img src={Australia} alt="img"  className=" style-img"  />
              </div>
            </div>
          </div>
          </div>
          <div className="container  mt-4 mb-2">
          <div className="row" style={{background:"#eff9ff" ,padding:"10px"}}>
        
        <div className="col-lg-6">
          <div className="">
            <img src={NewZealand} alt="img"  className=" style-img"  />
          </div>
        </div>
        <div className="col-lg-6 shadow-AEP">
          <div>
            <h2 className="text-center fs-1 font-bolds">
            New Zealand Spouse Visa
            </h2>
          <p>One of the most beautiful and peaceful countries, New Zealand, is an excellent choice for international students to live and study. If your partner wants to join you, he or she can apply for a Partner of a Student Work Visa or a Partner of a Student Visitor Visa with some conditions. If you are studying at a Level 7 or 8 qualification, which is also on New Zealand’s Long Term Skill Shortage List, or at a Level 9 or 10 qualification, your spouse may apply for a Partner of a Student Work Visa. With this visa, your partner will be able to work for any number of hours in New Zealand.</p>
          </div>
        </div>
      </div>
        </div>
        <section className='bg10 mt-5 mb-2'>
 <div className="container ">
      <div className="card p-5 shadow-sms mt-4 mb-2 " data-aos="fade-right">
        {/* <h2 className="text-center mb-4 font-bolds" >Earn Up-To 50,000 Every Month</h2> */}
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12 mt-2 mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Name" />
            </div>
            <div className="form-group col-md-12 mt-2  mb-2" >
              <input type="email" className="form-controls px-2 py-2" placeholder="Email Address" />
            </div>
            <div className="form-group col-md-12  mt-2  mb-2">
              <input type="text" className="form-controls px-2 py-2" placeholder="Mobile Number" />
            </div>
          </div>
          <div className="form-row  ">
            <div className="form-group col-lg-12 mt-2  mb-2">
              <select className="form-controls px-2 py-2">
                <option value="Retailer">Retailer</option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>
            <div className="form-group col-md-12 mt-2  mb-2">
              <button type="submit" className="btn btn-primarys ">Join Now <MdKeyboardDoubleArrowRight/></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </section>
      </>
    </div>
  )
}

export default DMT